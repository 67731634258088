$card-box-shadow: 2px 2px 24px 0 rgba(68, 68, 68, 0.16);
$dropdown-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);

$black-color: #22241b;
$active-color: #3d6e9c;
$primary-color: #3d6e9c;
$placeholder-color: #969696;

$gray-text: #858680;
$dark-orange: #ff8c00;

$table-header-active-col-color: #e6f1ff;
$table-header-color: #f3f5f8;
$table-row-color: #fafbfe;

$theme-light-color: #ffece3;
$form-border-color: #c8d1ff;
$form-placeholder-color: #747c85;
$disable-color: #797979;
$disable-background-color: #e8e8e8;

$modal-header-color: #e0edff;
$label-highlight-color: #2e86ff;
$blue-light-color: #ffffff;
$border-color: #e3e3e3;

$theme-bg-color: #f2f9ff;

$input-focus-blue-border-color: #2a6ca7;
$input-invalid-border-color: #f44336;

$card-border-color: #91c8d7;

$color-danger: #c42b31;
$color-success: #00a944;
$dark-blue-color: #0753c5;
$light-yellow-text: #fbb707;
$success-status-text: #00a944;
$color-white: white;
$color-light-green: #91c8d7;

$dropdown-placeholder-color: #495057;
$card-common-color: #ffffff;

$sub-type-bg: #f5f5f5;
$color-sky-blue: #007bff;
$icon-sky-blue: #0069d9;
