@import "src/assets/scss/theme/mixins";
@import "src/assets/scss/variables";

body {
  background-color: var(--surface-ground) !important;
  color: var(--text-color) !important;
  overflow: hidden !important;
}

form.submitted input.ng-invalid,
input.ng-dirty.ng-invalid {
  border: 1px solid red;
}

.login-input-group .password-eye {
  top: 25px !important;
}

label {
  color: var(--text-color) !important;
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 5px;
}

.action-wrapper {
  position: absolute;
  bottom: 20px;
}

a.active {
  color: #fff !important;
  text-decoration: none;
  background-color: $active-color !important;
}

fa-icon .fa-sort {
  font-size: 13px;
  color: $black-color;
}

fa-icon .fa-sort-up,
fa-icon .fa-sort-down {
  font-size: 13px;
  color: $active-color;
}

fa-icon .fa-sort-down {
  margin-bottom: 3px;
}

fa-icon .fa-sort-up {
  margin-bottom: -3px;
}

fa-icon:hover {
  cursor: pointer;
}

:root {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(158, 158, 158);
  }
}

.p-sidebar-content {
  padding: 0 !important;
  overflow-y: hidden;

  .content {
    padding: 20px;
    // position: relative;
    max-height: calc(100vh - 120px);
    // overflow-y: auto;
    height: 100%;
  }
}

.p-sidebar-content {
  position: relative;
}

.modal-title {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;

  * {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 0;
  }

  fa-icon {
    padding: 0px 8px;
    height: 1.8rem;
  }
}

.modal-footer {
  position: fixed;
  bottom: 0;
  padding: 0.3rem;
  width: -webkit-fill-available;
  flex-wrap: nowrap !important;
  z-index: 2000;
  min-height: 55px;
}

.p-sidebar-content {
  padding: 0 !important;
  overflow-y: hidden;

  .content {
    height: 100%;
    overflow-x: auto;
  }
}

.p-sidebar-content {
  position: relative;
}

.text-danger {
  color: red;
}

.icon {
  font-size: 1rem;
}

.left {
  position: relative;

  img {
    position: absolute;
    left: 10%;
    top: 24%;
  }

  span {
    margin-left: 10px;
  }
}

.scrolltop-position {
  bottom: 38px !important;
}

.pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.btn-focus:focus {
  position: relative;
  z-index: 0;
  background-color: rgba(141, 208, 255, 0.04);
  box-shadow: none;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: 70% 30%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;
    background-image: linear-gradient(var(--primary-color), var(--primary-color)),
      linear-gradient(var(--primary-color), var(--primary-color)),
      linear-gradient(var(--primary-color), var(--primary-color));
    animation: rotate 3s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: var(--surface-a);
    border-radius: 5px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="file"] {
  cursor: pointer;
}

.p-sidebar {
  .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar-content {
    height: 100vh;
  }
}

.hover-item {
  &:hover {
    color: #3d6e9c !important;
  }
}

@media (max-width: 600px) {
  .btn-add {
    padding: 0 15px !important;

    img {
      left: 12% !important;
    }
  }

  .hide-in-mobile {
    display: none;
  }
}

.fw-bold {
  font-weight: 700 !important;
}

.custom-a {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
}

.color-primary {
  color: var(--primary-color);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.opacity-70 {
  opacity: 0.7;
}

.fs-14px {
  font-size: 14px;
}

.fs-14px-imp {
  font-size: 14px;
}

.p-tabview-nav-content {
  .p-tabview-nav {
    border: none;

    li.p-highlight .p-tabview-nav-link {
      border: none;
      border-bottom: 3.5px solid $primary-color;
      color: $primary-color;
    }

    li {
      border: none;
      margin-right: 20px;

      .p-tabview-nav-link {
        border: none;
        color: $color-light-green;
        padding-right: 0;
        padding-left: 0;
      }

      a:hover {
        color: $primary-color !important;
      }
    }
  }
}

.p-tabview-panels {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 30px !important;
}

em {
  color: $color-light-green;
}
