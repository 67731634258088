/* You can add global styles to this file, and also import other style files */
@import "node_modules/primeng/resources/primeng.min.css";

@import "bootstrap/dist/css/bootstrap.css";
@import "assets/scss/variables";
@import "assets/scss/theme/mixins";
@import "assets/scss/theme/font";
@import "assets/scss/theme/theme";
@import "assets/scss/theme/button";
@import "assets/scss/theme/table";
@import "assets/scss/theme/card";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "assets/scss/custom";
@import "assets/scss/theme/sidebars";
@import "assets/scss/theme/form";
