@import "mixins";

a:hover {
  cursor: pointer;
}

body {
  height: 100vh;
}

.btn-loader-icon {
  position: absolute;
  margin-left: 80px;
  color: white;
  margin-top: 2px;
  animation-name: spin;
  animation-duration: 5000ms;
}

.container-fluid {
  padding-right: var(--bs-gutter-x, 1.75rem);
  padding-left: var(--bs-gutter-x, 1.75rem);
}
