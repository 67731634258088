@import "src/assets/scss/variables";

.form-control {
  border-radius: 9px;
  background-color: $blue-light-color !important;
  border-color: $border-color !important;
  box-shadow: 0 0 1px 1px $blue-light-color !important;

  &:focus {
    background-color: $blue-light-color !important;
    border-color: $active-color !important;
    outline: 0;
    box-shadow: 0 0 0 0.05rem $active-color;
  }
}

textarea {
  padding: 0.375rem 0.75rem;
}

.form-control {
  height: 48px;
}

.form-group {
  margin-bottom: 25px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $active-color;
  background: $active-color;
}

.p-confirm-popup {
  box-shadow: 0 2px 7px 1px #979797;
  border-radius: 10px;

  .p-confirm-popup-message {
    max-width: 500px;
  }
}

// .row {
//   --bs-gutter-y: 1rem !important;
// }

p-dropdown {
  display: block;
  width: 100%;
}

.p-dropdown-filter {
  height: 36px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.4rem 1.25rem;
}

.p-dropdown-panel {
  box-shadow: $dropdown-box-shadow;
}

.form-control:disabled,
.form-control[readonly] {
  color: $disable-color;
  background-color: $disable-background-color !important;
  opacity: 1;
}

.p-calendar {
  height: 48px;
  width: 100%;

  input {
    border-radius: 9px;
    background-color: $blue-light-color !important;
    border-color: $border-color !important;
    box-shadow: 0 0 1px 1px $blue-light-color !important;

    &:focus {
      background-color: $blue-light-color !important;
      border-color: $active-color !important;
      outline: 0;
      box-shadow: 0 0 0 0.05rem $active-color;
    }
  }

  .p-button {
    // background-color: $blue-light-color !important;
    // border-color: $border-color !important;
    font-weight: 600;

    .p-button-icon {
      color: $label-highlight-color;
    }
  }
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: white;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.position-relative {
  app-error-messages {
    left: 13px;
    bottom: -17px;
  }
}

*[class^="col-"] {
  position: relative;
}

.p-sidebar-content .content label {
  color: $black-color !important;
  font-weight: 400 !important;
}

p-radiobutton,
.p-radiobutton {
  vertical-align: middle;
}

.p-multiselect-panel {
  box-shadow: $dropdown-box-shadow;
}

.fw-bold {
  font-weight: 500 !important;
}

.text-reset {
  opacity: 0.6;
}

.mx-w-300 {
  max-width: 300px;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #fff;
  padding: 0.5rem 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  appearance: none;
}

.p-inputtext:enabled:hover {
  border-color: #ced4da;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $input-focus-blue-border-color !important;
  border-color: $input-focus-blue-border-color !important;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: $input-invalid-border-color;
  box-shadow: 0 0 0 0.2rem $input-invalid-border-color;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  box-shadow: 0 0 0 0.2rem $input-invalid-border-color;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-inputswitch {
  height: 20px;
  width: 42px;

  .p-inputswitch-slider {
    height: 20px;
    border-radius: 25px;
    width: 42px;
  }

  .p-inputswitch-slider:before {
    width: 1rem !important;
    height: 1rem !important;
    left: 0.2rem !important;
    margin-top: -0.52rem !important;
  }
}

.custom-input {
  display: block;
  width: 100%;
  color: $gray-text !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
}

.custom-input-edit {
  display: block;
  width: 100%;
  color: $black-color !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
}

.custom-dropdown {
  .p-dropdown-label {
    display: block;
    width: 100%;
    color: $gray-text !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
  }
}

.custom-dropdown-edit {
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: $dropdown-placeholder-color !important;
  }

  .p-dropdown-label {
    display: block;
    width: 100%;
    color: $black-color !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
  }
}

.custom-textarea {
  width: 100%;
  color: $gray-text !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-textarea-edit {
  width: 100%;
  color: $black-color !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-dropdown {
  background: #fff;
  border: 1px solid #dee2e6;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 0.375rem;
  width: 100%;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #2a6ca7;
  border-color: #2a6ca7;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  color: var(--black-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 11px 15px;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #fff;
  background: #2a6ca7;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #f44336;
}

.p-input-filled .p-dropdown {
  background: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.required {
  &:after {
    content: " *";
    color: $color-danger;
  }
}
