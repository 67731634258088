@import "src/assets/scss/variables";

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 0 !important;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  background: #d5d8d5 !important;
  color: $black-color !important;
  transition: box-shadow 0.15s;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--color-white);
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #fff;
  background: #2a6ca7;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: var(--primary-color);
  color: var(--color-white);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--color-white);
}

.p-datatable .p-sortable-column.p-highlight {
  background: var(--primary-color);
  color: var(--color-white);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--color-white);
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: var(--primary-color);
  color: var(--color-white);
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--color-white);
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem var(--activated-color);
  outline: 0 none;
}

.p-datatable-wrapper {
  border-radius: 15px;
}

.p-datatable .p-datatable-loading-icon {
  color: $primary-color;
  height: 40px;
  width: 40px;
}

.p-progress-spinner {
  width: 50px !important;
  height: 50px !important;
}
