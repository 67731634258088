@import "../variables";

.btn {
  height: 40px;
  padding: 0 40px !important;
}

a {
  color: $primary-color;
}

.p-button-primary {
  color: #fff;
  background-color: $primary-color !important;
  border-color: $primary-color !important;

  &:hover {
    color: #fff;
    background-color: lighten($primary-color, 10%);
    border-color: lighten($primary-color, 10%);
  }
}

.p-button-primary:disabled,
.p-button-primary.disabled {
  color: #fff;
  background-color: lighten($primary-color, 7%) !important;
  border-color: lighten($primary-color, 7%) !important;
}

.btn-check:checked + .p-button-primary,
.btn-check:active + .p-button-primary,
.p-button-primary:active,
.p-button-primary.active,
.show > .p-button-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.p-button {
  font-weight: 600;
  border-radius: 10px;
}

.nav-pills .nav-link.active {
  background-color: $active-color;
}

.nav-pills .show > .nav-link {
  background-color: $primary-color;
}

.nav-link {
  padding: 20px 25px;
  border-radius: 0 !important;
  color: white;
}

.dropdown-menu.show {
  border: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background-color: white;
  box-shadow: $dropdown-box-shadow;
  padding: 10px 0;

  .dropdown-item {
    color: $black-color;
    padding: 0.5rem 1rem;

    &:hover,
    &:focus {
      color: white;
      background-color: $primary-color;
    }
  }
}

.nav-link:hover,
.nav-link:focus {
  color: $active-color;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $placeholder-color;
}

.btn-check:focus + .p-button-primary,
.btn-primary:focus {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  box-shadow: 0 0 0 0.05rem $active-color;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
}

.p-button.p-button-text {
  background-color: transparent !important;
  border-color: transparent !important;
}

.p-button.p-button-text {
  color: var(--primary-color);
}
