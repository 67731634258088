@import "src/assets/scss/variables";

.p-card {
  background: #fff !important;
  color: #212529;
  margin-top: 20px;
  box-shadow: none;
  border-radius: 10px;
}

.p-card .p-card-body {
  padding: 0;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}

.p-card .p-card-content {
  padding: 0;
}

.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.section-card {
  padding: 30px 50px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

@media screen and (max-width: 576px) {
  .section-card {
    padding: 20px;
  }
}

.card-header {
  color: $black-color;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
