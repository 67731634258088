@media only screen and (max-width: 700px) {
  .p-sidebar-left.p-sidebar-md,
  .p-sidebar-right.p-sidebar-md {
    width: 100% !important;
  }

  .p-sidebar-md {
    width: 100% !important;
  }
}

.p-sidebar-left.p-sidebar-md,
.p-sidebar-right.p-sidebar-md {
  width: 44rem;
}
